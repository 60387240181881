import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { UserInfo } from "@/types/UserInfo";

type UsersParams = {
  password: string;
  timezone?: string;
};

export const apiGetUserInfo = (): AxiosPromise<UserInfo> => {
  return ApiService.get("/api/billing/user/info");
};

export const apiUpdateUser = (params: UsersParams): AxiosPromise => {
  return ApiService.post("/api/billing/user/update", params);
};
