
import { defineComponent, ref, watch, reactive } from "vue";
import timezones from "@/common/timezones";
import { apiUpdateUser } from "@/api/user";
import { useStore } from "vuex";
import useNotify from "@/composables/useNotify";

export default defineComponent({
  name: "Profile",
  components: {},
  setup() {
    const store = useStore();

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 12 },
    };
    const email = ref(store.state.auth.user.email);
    const status = ref("");
    const password = ref("");
    const repeatPassword = ref("");
    const isDisabledButton = ref(false);
    const isLoading = ref(false);

    type Timezone = { key?: string };
    const userTimeZone: Timezone = reactive({
      key: store.state.auth.user.timezone,
    });

    const notify = useNotify();

    const openNotificationWithIcon = () => {
      notify.sendNotify("success", "Updated", "Your data has been updated");
    };

    const update = async () => {
      isDisabledButton.value = true;
      isLoading.value = true;
      const query = {
        password: "",
        timezone: userTimeZone.key,
      };
      if (status.value === "success") {
        query.password = password.value;
      }
      await apiUpdateUser(query);
      openNotificationWithIcon();
      password.value = "";
      repeatPassword.value = "";
      status.value = "";
      isLoading.value = false;
    };

    watch(repeatPassword, () => {
      if (password.value !== "") {
        if (password.value === repeatPassword.value) {
          status.value = "success";
        } else {
          status.value = "error";
        }
      }
    });

    return {
      timezones,
      formItemLayout,
      repeatPassword,
      update,
      userTimeZone,
      email,
      status,
      password,
      isDisabledButton,
      isLoading,
    };
  },
});
