export default [
  { zone: "Pacific/Midway", region: "(GMT-11:00) Midway Island" },
  { zone: "US/Samoa", region: "(GMT-11:00) Samoa" },
  { zone: "US/Hawaii", region: "(GMT-10:00) Hawaii" },
  { zone: "US/Alaska", region: "(GMT-09:00) Alaska" },
  { zone: "US/Pacific", region: "(GMT-08:00) Pacific Time (US &amp; Canada)" },
  { zone: "America/Tijuana", region: "(GMT-08:00) Tijuana" },
  { zone: "US/Arizona", region: "(GMT-07:00) Arizona" },
  {
    zone: "US/Mountain",
    region: "(GMT-07:00) Mountain Time (US &amp; Canada)",
  },
  { zone: "America/Chihuahua", region: "(GMT-07:00) Chihuahua" },
  { zone: "America/Mazatlan", region: "(GMT-07:00) Mazatlan" },
  { zone: "America/Mexico_City", region: "(GMT-06:00) Mexico City" },
  { zone: "America/Monterrey", region: "(GMT-06:00) Monterrey" },
  { zone: "Canada/Saskatchewan", region: "(GMT-06:00) Saskatchewan" },
  { zone: "US/Central", region: "(GMT-06:00) Central Time (US &amp; Canada)" },
  { zone: "US/Eastern", region: "(GMT-05:00) Eastern Time (US &amp; Canada)" },
  { zone: "US/East-Indiana", region: "(GMT-05:00) Indiana (East)" },
  { zone: "America/Bogota", region: "(GMT-05:00) Bogota" },
  { zone: "America/Lima", region: "(GMT-05:00) Lima" },
  { zone: "America/Caracas", region: "(GMT-04:30) Caracas" },
  { zone: "Canada/Atlantic", region: "(GMT-04:00) Atlantic Time (Canada)" },
  { zone: "America/La_Paz", region: "(GMT-04:00) La Paz" },
  { zone: "America/Santiago", region: "(GMT-04:00) Santiago" },
  { zone: "Canada/Newfoundland", region: "(GMT-03:30) Newfoundland" },
  { zone: "America/Buenos_Aires", region: "(GMT-03:00) Buenos Aires" },
  { zone: "Greenland", region: "(GMT-03:00) Greenland" },
  { zone: "Atlantic/Stanley", region: "(GMT-02:00) Stanley" },
  { zone: "Atlantic/Azores", region: "(GMT-01:00) Azores" },
  { zone: "Atlantic/Cape_Verde", region: "(GMT-01:00) Cape Verde Is." },
  { zone: "Africa/Casablanca", region: "(GMT) Casablanca" },
  { zone: "UTC", region: "(GMT) Dublin" },
  { zone: "Europe/Lisbon", region: "(GMT) Lisbon" },
  { zone: "Europe/London", region: "(GMT) London" },
  { zone: "Africa/Monrovia", region: "(GMT) Monrovia" },
  { zone: "Europe/Amsterdam", region: "(GMT+01:00) Amsterdam" },
  { zone: "Europe/Belgrade", region: "(GMT+01:00) Belgrade" },
  { zone: "Europe/Berlin", region: "(GMT+01:00) Berlin" },
  { zone: "Europe/Bratislava", region: "(GMT+01:00) Bratislava" },
  { zone: "Europe/Brussels", region: "(GMT+01:00) Brussels" },
  { zone: "Europe/Budapest", region: "(GMT+01:00) Budapest" },
  { zone: "Europe/Copenhagen", region: "(GMT+01:00) Copenhagen" },
  { zone: "Europe/Ljubljana", region: "(GMT+01:00) Ljubljana" },
  { zone: "Europe/Madrid", region: "(GMT+01:00) Madrid" },
  { zone: "Europe/Paris", region: "(GMT+01:00) Paris" },
  { zone: "Europe/Prague", region: "(GMT+01:00) Prague" },
  { zone: "Europe/Rome", region: "(GMT+01:00) Rome" },
  { zone: "Europe/Sarajevo", region: "(GMT+01:00) Sarajevo" },
  { zone: "Europe/Skopje", region: "(GMT+01:00) Skopje" },
  { zone: "Europe/Stockholm", region: "(GMT+01:00) Stockholm" },
  { zone: "Europe/Vienna", region: "(GMT+01:00) Vienna" },
  { zone: "Europe/Warsaw", region: "(GMT+01:00) Warsaw" },
  { zone: "Europe/Zagreb", region: "(GMT+01:00) Zagreb" },
  { zone: "Europe/Athens", region: "(GMT+02:00) Athens" },
  { zone: "Europe/Bucharest", region: "(GMT+02:00) Bucharest" },
  { zone: "Africa/Cairo", region: "(GMT+02:00) Cairo" },
  { zone: "Africa/Harare", region: "(GMT+02:00) Harare" },
  { zone: "Europe/Helsinki", region: "(GMT+02:00) Helsinki" },
  { zone: "Europe/Istanbul", region: "(GMT+02:00) Istanbul" },
  { zone: "Asia/Jerusalem", region: "(GMT+02:00) Jerusalem" },
  { zone: "Europe/Kiev", region: "(GMT+02:00) Kyiv" },
  { zone: "Europe/Minsk", region: "(GMT+02:00) Minsk" },
  { zone: "Europe/Riga", region: "(GMT+02:00) Riga" },
  { zone: "Europe/Sofia", region: "(GMT+02:00) Sofia" },
  { zone: "Europe/Tallinn", region: "(GMT+02:00) Tallinn" },
  { zone: "Europe/Vilnius", region: "(GMT+02:00) Vilnius" },
  { zone: "Asia/Baghdad", region: "(GMT+03:00) Baghdad" },
  { zone: "Asia/Kuwait", region: "(GMT+03:00) Kuwait" },
  { zone: "Africa/Nairobi", region: "(GMT+03:00) Nairobi" },
  { zone: "Asia/Riyadh", region: "(GMT+03:00) Riyadh" },
  { zone: "Europe/Moscow", region: "(GMT+03:00) Moscow" },
  { zone: "Asia/Tehran", region: "(GMT+03:30) Tehran" },
  { zone: "Asia/Baku", region: "(GMT+04:00) Baku" },
  { zone: "Europe/Volgograd", region: "(GMT+04:00) Volgograd" },
  { zone: "Asia/Muscat", region: "(GMT+04:00) Muscat" },
  { zone: "Asia/Tbilisi", region: "(GMT+04:00) Tbilisi" },
  { zone: "Asia/Yerevan", region: "(GMT+04:00) Yerevan" },
  { zone: "Asia/Kabul", region: "(GMT+04:30) Kabul" },
  { zone: "Asia/Karachi", region: "(GMT+05:00) Karachi" },
  { zone: "Asia/Tashkent", region: "(GMT+05:00) Tashkent" },
  { zone: "Asia/Kolkata", region: "(GMT+05:30) Kolkata" },
  { zone: "Asia/Kathmandu", region: "(GMT+05:45) Kathmandu" },
  { zone: "Asia/Yekaterinburg", region: "(GMT+06:00) Ekaterinburg" },
  { zone: "Asia/Almaty", region: "(GMT+06:00) Almaty" },
  { zone: "Asia/Dhaka", region: "(GMT+06:00) Dhaka" },
  { zone: "Asia/Novosibirsk", region: "(GMT+07:00) Novosibirsk" },
  { zone: "Asia/Bangkok", region: "(GMT+07:00) Bangkok" },
  { zone: "Asia/Jakarta", region: "(GMT+07:00) Jakarta" },
  { zone: "Asia/Krasnoyarsk", region: "(GMT+08:00) Krasnoyarsk" },
  { zone: "Asia/Chongqing", region: "(GMT+08:00) Chongqing" },
  { zone: "Asia/Hong_Kong", region: "(GMT+08:00) Hong Kong" },
  { zone: "Asia/Kuala_Lumpur", region: "(GMT+08:00) Kuala Lumpur" },
  { zone: "Australia/Perth", region: "(GMT+08:00) Perth" },
  { zone: "Asia/Singapore", region: "(GMT+08:00) Singapore" },
  { zone: "Asia/Taipei", region: "(GMT+08:00) Taipei" },
  { zone: "Asia/Ulaanbaatar", region: "(GMT+08:00) Ulaan Bataar" },
  { zone: "Asia/Urumqi", region: "(GMT+08:00) Urumqi" },
  { zone: "Asia/Irkutsk", region: "(GMT+09:00) Irkutsk" },
  { zone: "Asia/Seoul", region: "(GMT+09:00) Seoul" },
  { zone: "Asia/Tokyo", region: "(GMT+09:00) Tokyo" },
  { zone: "Australia/Adelaide", region: "(GMT+09:30) Adelaide" },
  { zone: "Australia/Darwin", region: "(GMT+09:30) Darwin" },
  { zone: "Asia/Yakutsk", region: "(GMT+10:00) Yakutsk" },
  { zone: "Australia/Brisbane", region: "(GMT+10:00) Brisbane" },
  { zone: "Australia/Canberra", region: "(GMT+10:00) Canberra" },
  { zone: "Pacific/Guam", region: "(GMT+10:00) Guam" },
  { zone: "Australia/Hobart", region: "(GMT+10:00) Hobart" },
  { zone: "Australia/Melbourne", region: "(GMT+10:00) Melbourne" },
  { zone: "Pacific/Port_Moresby", region: "(GMT+10:00) Port Moresby" },
  { zone: "Australia/Sydney", region: "(GMT+10:00) Sydney" },
  { zone: "Asia/Vladivostok", region: "(GMT+11:00) Vladivostok" },
  { zone: "Asia/Magadan", region: "(GMT+12:00) Magadan" },
  { zone: "Pacific/Auckland", region: "(GMT+12:00) Auckland" },
  { zone: "Pacific/Fiji", region: "(GMT+12:00) Fiji" },
];
